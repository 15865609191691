import { createSlice } from "@reduxjs/toolkit"
import { sortImageByStatus } from "../views/tenue/saisieUniverselle/saisie/services/su.service"

export const saisieUniverselleSlice = createSlice({
    name: 'saisieUniverselle',
    initialState: {
        suDefaultClass: {
            col: 'mb-half',
            label: 'form-label fw-bolder'
        },
        suImages: [],
        suSelectedImage: {},
        suStatus: null,
        suCategories: [],
        suSousCategories: [],
        suSousSousCategories: [],
        suDecisions: [],
        suSousSousCategorieMotCles: [],
        suCategorieFromMotCles: {
            sousCategoriesList: [],
            sousSousCategoriesList: []
        },
        suPccList: {
            dossier_id: 0,
            pcc: [],
            tiers: []
        },
        suTypeAchatVentes: [],
        suTypePieces: [],
        suCategorieTypePiece: [],
        suTypeRechercheEcriture: [],
        suImageData: {
            image_id: 0,
            dossier_id: 0,
            journal_dossier: [],
            refresh: false 
        },
        suPays: [],
        suDevises: [],
        // ** Fields 
        suSirenField: {},
        suTypeAVField: {},
        suDateFactureField: {},
        suNatureEnregistrerBySiren: [],
        suSiretNature: [],
        suModeReglement: [],
        suTvaTaux: [],
        suNatureSu: [],
        suLibelleModele: [],
        suLibelleItem: [],
        suChamps: [],
        champSelectedOcr : { 
            mono: '',
            multi: [] 
        },
        // champSelectedOcr : { mono: {cle:'', coordonnee: {}}, multi: [] },
        suOcrTag: {
            mono: '',
            multi: {
                codeArt: '', 
                qte: 1,
                ht: 0,
                codeTva: '',
                mntTva: 0,
                rabais: 0, 
                libelle: '',
                pu: 0,
                ttc: 0,
                taux: 0,
                remise: 0
            }
        }
    },
    reducers: {
        fetchSuDefaultClass: (state, action) => {
            state.suDefaultClass = {...state.suDefaultClass,
                col: action.payload.col,
                label: action.payload.label
            }
        },
        fetchSuImages: (state, action) => {
            state.suImages = sortImageByStatus(action.payload)
        },
        handleSuSelectedImage: (state, action) => {
            state.suSelectedImage = action.payload
        },
        fetchSuCategories: (state, action) => {
            state.suCategories = action.payload
        },
        fetchSuSousCategories: (state, action) => {
            state.suSousCategories = action.payload
        },
        fetchSuSousSousCategories: (state, action) => {
            state.suSousSousCategories = action.payload
        },
        fetchSuDecisions: (state, action) => {
            state.suDecisions = action.payload
        },
        fetchSuSousSousCategorieMotCles: (state, action) => {
            state.suSousSousCategorieMotCles = action.payload
        },
        fetchSuPccList: (state, action) => {
            state.suPccList = {...state.suPccList,
                dossier_id: action.payload.dossier_id,
                pcc: action.payload.pcc,
                tiers: action.payload.tiers
            }
        },
        fetchSuCategorieFromMotCles: (state, action) => {
            state.suCategorieFromMotCles = {...state.suCategorieFromMotCles, 
                sousCategoriesList: action.payload.sousCategoriesList, 
                sousSousCategoriesList: action.payload.sousSousCategoriesList
            }
        },
        fetchSuTypeAchatVentes: (state, action) => {
            state.suTypeAchatVentes = action.payload
        },
        fetchSuTypePieces: (state, action) => {
            state.suTypePieces = action.payload
        },
        fetchSuCategorieTypePieces: (state, action) => {
            state.suCategorieTypePieces = action.payload
        },
        fetchSuTypeRechercheEcriture: (state, action) => {
            state.suTypeRechercheEcriture = action.payload
        },
        // fetchSuJournalDossier: (state, action) => {
        //     state.suJournalDossier = action.payload
        // },
        fetchSuImageData: (state, action) => {
            state.suImageData = {...action.payload}
        },
        fetchSuPays: (state, action) => {
            state.suPays = action.payload
        },
        fetchSuDevises: (state, action) => {
            state.suDevises = action.payload
        },
        // ** Fetching fields
        fetchSuSirenField: (state, action) => {
            state.suSirenField = action.payload
        },
        fetchSuTypeAVField: (state, action) => {
            state.suTypeAVField = action.payload
        },
        fetchSuDateFactureField: (state, action) => {
            state.suDateFactureField = action.payload
        },
        fetchSuNatureEnregistrerBySiren: (state, action) => {
            state.suNatureEnregistrerBySiren = action.payload
        },
        fetchSuSiretNature: (state, action) => {
            state.suSiretNature = action.payload
        },
        fetchSuModeReglement: (state, action) => {
            state.suModeReglement = action.payload
        },
        fetchTvaTaux: (state, action) => {
            state.suTvaTaux = action.payload
        },
        fetchNatureSu: (state, action) => {
            state.suNatureSu = action.payload
        },
        fetchLibelleModele: (state, action) => {
            state.suLibelleModele = action.payload
        },
        fetchLibelleItem: (state, action) => {
            state.suLibelleItem = action.payload
        },
        fetchSuOcrTag: (state, action) => {
            state.suOcrTag = action.payload
        },
        fetchSuChamps: (state, action) => {
            state.suChamps = action.payload
        },
        fetchChampSelectedOcr: (state, action) => {
            state.champSelectedOcr = {...state.champSelectedOcr,
                mono: action.payload.mono,
                multi: action.payload.multi
            }
        }
    }
})

export const {
    fetchSuDefaultClass,
    fetchSuImages,
    handleSuSelectedImage,
    fetchSuCategories,
    fetchSuSousCategories,
    fetchSuSousSousCategories,
    fetchSuDecisions,
    fetchSuSousSousCategorieMotCles,
    fetchSuCategorieFromMotCles,
    fetchSuPccList,
    fetchSuTypeAchatVentes,
    fetchSuTypePieces,
    fetchSuCategorieTypePieces,
    fetchSuTypeRechercheEcriture,
    // fetchSuJournalDossier,
    fetchSuImageData,
    fetchSuPays,
    fetchSuDevises,
    fetchSuNatureEnregistrerBySiren,
    fetchSuSiretNature,
    fetchSuModeReglement,
    fetchTvaTaux,
    fetchNatureSu,
    fetchLibelleModele,
    fetchLibelleItem,
    // ** Importing field
    fetchSuSirenField,
    fetchSuTypeAVField,
    fetchSuDateFactureField,
    fetchSuOcrTag,
    fetchSuChamps,
    fetchChampSelectedOcr
} = saisieUniverselleSlice.actions

export default saisieUniverselleSlice.reducer 